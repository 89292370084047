/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedNavigableImport } from './routes/_authenticated/_navigable'
import { Route as AuthenticatedNavigableIndexImport } from './routes/_authenticated/_navigable/_index'
import { Route as AuthenticatedNavigableGamesTurbVaultImport } from './routes/_authenticated/_navigable/games/turb-vault'

// Create Virtual Routes

const AuthorizePhantomLazyImport = createFileRoute('/authorize/phantom')()
const GamesTreasureShareLazyImport = createFileRoute('/games/treasure/share')()
const AuthenticatedNavigableWalletIndexLazyImport = createFileRoute(
  '/_authenticated/_navigable/wallet/',
)()
const AuthenticatedNavigableRankingIndexLazyImport = createFileRoute(
  '/_authenticated/_navigable/ranking/',
)()
const AuthenticatedNavigableQuestIndexLazyImport = createFileRoute(
  '/_authenticated/_navigable/quest/',
)()
const AuthenticatedNavigableProfileIndexLazyImport = createFileRoute(
  '/_authenticated/_navigable/profile/',
)()
const AuthenticatedNavigableIndexIndexLazyImport = createFileRoute(
  '/_authenticated/_navigable/_index/',
)()
const AuthenticatedNavigableWalletWithdrawWalletLazyImport = createFileRoute(
  '/_authenticated/_navigable/wallet/withdraw-wallet',
)()
const AuthenticatedNavigableWalletWithdrawLazyImport = createFileRoute(
  '/_authenticated/_navigable/wallet/withdraw',
)()
const AuthenticatedNavigableWalletDepositLazyImport = createFileRoute(
  '/_authenticated/_navigable/wallet/deposit',
)()
const AuthenticatedNavigableWalletAddressLazyImport = createFileRoute(
  '/_authenticated/_navigable/wallet/$address',
)()
const AuthenticatedNavigableGamesTreasureLazyImport = createFileRoute(
  '/_authenticated/_navigable/games/treasure',
)()
const AuthenticatedNavigableGamesDonateListLazyImport = createFileRoute(
  '/_authenticated/_navigable/games/donate-list',
)()
const AuthenticatedNavigableIndexCommunitiesMintLazyImport = createFileRoute(
  '/_authenticated/_navigable/_index/communities/$mint',
)()

// Create/Update Routes

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizePhantomLazyRoute = AuthorizePhantomLazyImport.update({
  id: '/authorize/phantom',
  path: '/authorize/phantom',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/authorize/phantom.lazy').then((d) => d.Route),
)

const AuthenticatedNavigableRoute = AuthenticatedNavigableImport.update({
  id: '/_navigable',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedNavigableIndexRoute =
  AuthenticatedNavigableIndexImport.update({
    id: '/_index',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any)

const GamesTreasureShareLazyRoute = GamesTreasureShareLazyImport.update({
  id: '/games/treasure/share',
  path: '/games/treasure/share',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/games/treasure/share.lazy').then((d) => d.Route),
)

const AuthenticatedNavigableWalletIndexLazyRoute =
  AuthenticatedNavigableWalletIndexLazyImport.update({
    id: '/wallet/',
    path: '/wallet/',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/wallet/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableRankingIndexLazyRoute =
  AuthenticatedNavigableRankingIndexLazyImport.update({
    id: '/ranking/',
    path: '/ranking/',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/ranking/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableQuestIndexLazyRoute =
  AuthenticatedNavigableQuestIndexLazyImport.update({
    id: '/quest/',
    path: '/quest/',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/quest/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableProfileIndexLazyRoute =
  AuthenticatedNavigableProfileIndexLazyImport.update({
    id: '/profile/',
    path: '/profile/',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/profile/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableIndexIndexLazyRoute =
  AuthenticatedNavigableIndexIndexLazyImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthenticatedNavigableIndexRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/_index/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableWalletWithdrawWalletLazyRoute =
  AuthenticatedNavigableWalletWithdrawWalletLazyImport.update({
    id: '/wallet/withdraw-wallet',
    path: '/wallet/withdraw-wallet',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_navigable/wallet/withdraw-wallet.lazy'
    ).then((d) => d.Route),
  )

const AuthenticatedNavigableWalletWithdrawLazyRoute =
  AuthenticatedNavigableWalletWithdrawLazyImport.update({
    id: '/wallet/withdraw',
    path: '/wallet/withdraw',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/wallet/withdraw.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableWalletDepositLazyRoute =
  AuthenticatedNavigableWalletDepositLazyImport.update({
    id: '/wallet/deposit',
    path: '/wallet/deposit',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/wallet/deposit.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableWalletAddressLazyRoute =
  AuthenticatedNavigableWalletAddressLazyImport.update({
    id: '/wallet/$address',
    path: '/wallet/$address',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/wallet/$address.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableGamesTreasureLazyRoute =
  AuthenticatedNavigableGamesTreasureLazyImport.update({
    id: '/games/treasure',
    path: '/games/treasure',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/games/treasure.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableGamesDonateListLazyRoute =
  AuthenticatedNavigableGamesDonateListLazyImport.update({
    id: '/games/donate-list',
    path: '/games/donate-list',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_navigable/games/donate-list.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedNavigableGamesTurbVaultRoute =
  AuthenticatedNavigableGamesTurbVaultImport.update({
    id: '/games/turb-vault',
    path: '/games/turb-vault',
    getParentRoute: () => AuthenticatedNavigableRoute,
  } as any)

const AuthenticatedNavigableIndexCommunitiesMintLazyRoute =
  AuthenticatedNavigableIndexCommunitiesMintLazyImport.update({
    id: '/communities/$mint',
    path: '/communities/$mint',
    getParentRoute: () => AuthenticatedNavigableIndexRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_navigable/_index/communities/$mint.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_navigable': {
      id: '/_authenticated/_navigable'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedNavigableImport
      parentRoute: typeof AuthenticatedImport
    }
    '/authorize/phantom': {
      id: '/authorize/phantom'
      path: '/authorize/phantom'
      fullPath: '/authorize/phantom'
      preLoaderRoute: typeof AuthorizePhantomLazyImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_navigable/_index': {
      id: '/_authenticated/_navigable/_index'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedNavigableIndexImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/games/treasure/share': {
      id: '/games/treasure/share'
      path: '/games/treasure/share'
      fullPath: '/games/treasure/share'
      preLoaderRoute: typeof GamesTreasureShareLazyImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_navigable/games/turb-vault': {
      id: '/_authenticated/_navigable/games/turb-vault'
      path: '/games/turb-vault'
      fullPath: '/games/turb-vault'
      preLoaderRoute: typeof AuthenticatedNavigableGamesTurbVaultImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/games/donate-list': {
      id: '/_authenticated/_navigable/games/donate-list'
      path: '/games/donate-list'
      fullPath: '/games/donate-list'
      preLoaderRoute: typeof AuthenticatedNavigableGamesDonateListLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/games/treasure': {
      id: '/_authenticated/_navigable/games/treasure'
      path: '/games/treasure'
      fullPath: '/games/treasure'
      preLoaderRoute: typeof AuthenticatedNavigableGamesTreasureLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/wallet/$address': {
      id: '/_authenticated/_navigable/wallet/$address'
      path: '/wallet/$address'
      fullPath: '/wallet/$address'
      preLoaderRoute: typeof AuthenticatedNavigableWalletAddressLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/wallet/deposit': {
      id: '/_authenticated/_navigable/wallet/deposit'
      path: '/wallet/deposit'
      fullPath: '/wallet/deposit'
      preLoaderRoute: typeof AuthenticatedNavigableWalletDepositLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/wallet/withdraw': {
      id: '/_authenticated/_navigable/wallet/withdraw'
      path: '/wallet/withdraw'
      fullPath: '/wallet/withdraw'
      preLoaderRoute: typeof AuthenticatedNavigableWalletWithdrawLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/wallet/withdraw-wallet': {
      id: '/_authenticated/_navigable/wallet/withdraw-wallet'
      path: '/wallet/withdraw-wallet'
      fullPath: '/wallet/withdraw-wallet'
      preLoaderRoute: typeof AuthenticatedNavigableWalletWithdrawWalletLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/_index/': {
      id: '/_authenticated/_navigable/_index/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedNavigableIndexIndexLazyImport
      parentRoute: typeof AuthenticatedNavigableIndexImport
    }
    '/_authenticated/_navigable/profile/': {
      id: '/_authenticated/_navigable/profile/'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthenticatedNavigableProfileIndexLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/quest/': {
      id: '/_authenticated/_navigable/quest/'
      path: '/quest'
      fullPath: '/quest'
      preLoaderRoute: typeof AuthenticatedNavigableQuestIndexLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/ranking/': {
      id: '/_authenticated/_navigable/ranking/'
      path: '/ranking'
      fullPath: '/ranking'
      preLoaderRoute: typeof AuthenticatedNavigableRankingIndexLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/wallet/': {
      id: '/_authenticated/_navigable/wallet/'
      path: '/wallet'
      fullPath: '/wallet'
      preLoaderRoute: typeof AuthenticatedNavigableWalletIndexLazyImport
      parentRoute: typeof AuthenticatedNavigableImport
    }
    '/_authenticated/_navigable/_index/communities/$mint': {
      id: '/_authenticated/_navigable/_index/communities/$mint'
      path: '/communities/$mint'
      fullPath: '/communities/$mint'
      preLoaderRoute: typeof AuthenticatedNavigableIndexCommunitiesMintLazyImport
      parentRoute: typeof AuthenticatedNavigableIndexImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedNavigableIndexRouteChildren {
  AuthenticatedNavigableIndexIndexLazyRoute: typeof AuthenticatedNavigableIndexIndexLazyRoute
  AuthenticatedNavigableIndexCommunitiesMintLazyRoute: typeof AuthenticatedNavigableIndexCommunitiesMintLazyRoute
}

const AuthenticatedNavigableIndexRouteChildren: AuthenticatedNavigableIndexRouteChildren =
  {
    AuthenticatedNavigableIndexIndexLazyRoute:
      AuthenticatedNavigableIndexIndexLazyRoute,
    AuthenticatedNavigableIndexCommunitiesMintLazyRoute:
      AuthenticatedNavigableIndexCommunitiesMintLazyRoute,
  }

const AuthenticatedNavigableIndexRouteWithChildren =
  AuthenticatedNavigableIndexRoute._addFileChildren(
    AuthenticatedNavigableIndexRouteChildren,
  )

interface AuthenticatedNavigableRouteChildren {
  AuthenticatedNavigableIndexRoute: typeof AuthenticatedNavigableIndexRouteWithChildren
  AuthenticatedNavigableGamesTurbVaultRoute: typeof AuthenticatedNavigableGamesTurbVaultRoute
  AuthenticatedNavigableGamesDonateListLazyRoute: typeof AuthenticatedNavigableGamesDonateListLazyRoute
  AuthenticatedNavigableGamesTreasureLazyRoute: typeof AuthenticatedNavigableGamesTreasureLazyRoute
  AuthenticatedNavigableWalletAddressLazyRoute: typeof AuthenticatedNavigableWalletAddressLazyRoute
  AuthenticatedNavigableWalletDepositLazyRoute: typeof AuthenticatedNavigableWalletDepositLazyRoute
  AuthenticatedNavigableWalletWithdrawLazyRoute: typeof AuthenticatedNavigableWalletWithdrawLazyRoute
  AuthenticatedNavigableWalletWithdrawWalletLazyRoute: typeof AuthenticatedNavigableWalletWithdrawWalletLazyRoute
  AuthenticatedNavigableProfileIndexLazyRoute: typeof AuthenticatedNavigableProfileIndexLazyRoute
  AuthenticatedNavigableQuestIndexLazyRoute: typeof AuthenticatedNavigableQuestIndexLazyRoute
  AuthenticatedNavigableRankingIndexLazyRoute: typeof AuthenticatedNavigableRankingIndexLazyRoute
  AuthenticatedNavigableWalletIndexLazyRoute: typeof AuthenticatedNavigableWalletIndexLazyRoute
}

const AuthenticatedNavigableRouteChildren: AuthenticatedNavigableRouteChildren =
  {
    AuthenticatedNavigableIndexRoute:
      AuthenticatedNavigableIndexRouteWithChildren,
    AuthenticatedNavigableGamesTurbVaultRoute:
      AuthenticatedNavigableGamesTurbVaultRoute,
    AuthenticatedNavigableGamesDonateListLazyRoute:
      AuthenticatedNavigableGamesDonateListLazyRoute,
    AuthenticatedNavigableGamesTreasureLazyRoute:
      AuthenticatedNavigableGamesTreasureLazyRoute,
    AuthenticatedNavigableWalletAddressLazyRoute:
      AuthenticatedNavigableWalletAddressLazyRoute,
    AuthenticatedNavigableWalletDepositLazyRoute:
      AuthenticatedNavigableWalletDepositLazyRoute,
    AuthenticatedNavigableWalletWithdrawLazyRoute:
      AuthenticatedNavigableWalletWithdrawLazyRoute,
    AuthenticatedNavigableWalletWithdrawWalletLazyRoute:
      AuthenticatedNavigableWalletWithdrawWalletLazyRoute,
    AuthenticatedNavigableProfileIndexLazyRoute:
      AuthenticatedNavigableProfileIndexLazyRoute,
    AuthenticatedNavigableQuestIndexLazyRoute:
      AuthenticatedNavigableQuestIndexLazyRoute,
    AuthenticatedNavigableRankingIndexLazyRoute:
      AuthenticatedNavigableRankingIndexLazyRoute,
    AuthenticatedNavigableWalletIndexLazyRoute:
      AuthenticatedNavigableWalletIndexLazyRoute,
  }

const AuthenticatedNavigableRouteWithChildren =
  AuthenticatedNavigableRoute._addFileChildren(
    AuthenticatedNavigableRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedNavigableRoute: typeof AuthenticatedNavigableRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedNavigableRoute: AuthenticatedNavigableRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedNavigableIndexRouteWithChildren
  '/authorize/phantom': typeof AuthorizePhantomLazyRoute
  '/games/treasure/share': typeof GamesTreasureShareLazyRoute
  '/games/turb-vault': typeof AuthenticatedNavigableGamesTurbVaultRoute
  '/games/donate-list': typeof AuthenticatedNavigableGamesDonateListLazyRoute
  '/games/treasure': typeof AuthenticatedNavigableGamesTreasureLazyRoute
  '/wallet/$address': typeof AuthenticatedNavigableWalletAddressLazyRoute
  '/wallet/deposit': typeof AuthenticatedNavigableWalletDepositLazyRoute
  '/wallet/withdraw': typeof AuthenticatedNavigableWalletWithdrawLazyRoute
  '/wallet/withdraw-wallet': typeof AuthenticatedNavigableWalletWithdrawWalletLazyRoute
  '/': typeof AuthenticatedNavigableIndexIndexLazyRoute
  '/profile': typeof AuthenticatedNavigableProfileIndexLazyRoute
  '/quest': typeof AuthenticatedNavigableQuestIndexLazyRoute
  '/ranking': typeof AuthenticatedNavigableRankingIndexLazyRoute
  '/wallet': typeof AuthenticatedNavigableWalletIndexLazyRoute
  '/communities/$mint': typeof AuthenticatedNavigableIndexCommunitiesMintLazyRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedNavigableRouteWithChildren
  '/authorize/phantom': typeof AuthorizePhantomLazyRoute
  '/games/treasure/share': typeof GamesTreasureShareLazyRoute
  '/games/turb-vault': typeof AuthenticatedNavigableGamesTurbVaultRoute
  '/games/donate-list': typeof AuthenticatedNavigableGamesDonateListLazyRoute
  '/games/treasure': typeof AuthenticatedNavigableGamesTreasureLazyRoute
  '/wallet/$address': typeof AuthenticatedNavigableWalletAddressLazyRoute
  '/wallet/deposit': typeof AuthenticatedNavigableWalletDepositLazyRoute
  '/wallet/withdraw': typeof AuthenticatedNavigableWalletWithdrawLazyRoute
  '/wallet/withdraw-wallet': typeof AuthenticatedNavigableWalletWithdrawWalletLazyRoute
  '/': typeof AuthenticatedNavigableIndexIndexLazyRoute
  '/profile': typeof AuthenticatedNavigableProfileIndexLazyRoute
  '/quest': typeof AuthenticatedNavigableQuestIndexLazyRoute
  '/ranking': typeof AuthenticatedNavigableRankingIndexLazyRoute
  '/wallet': typeof AuthenticatedNavigableWalletIndexLazyRoute
  '/communities/$mint': typeof AuthenticatedNavigableIndexCommunitiesMintLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_authenticated/_navigable': typeof AuthenticatedNavigableRouteWithChildren
  '/authorize/phantom': typeof AuthorizePhantomLazyRoute
  '/_authenticated/_navigable/_index': typeof AuthenticatedNavigableIndexRouteWithChildren
  '/games/treasure/share': typeof GamesTreasureShareLazyRoute
  '/_authenticated/_navigable/games/turb-vault': typeof AuthenticatedNavigableGamesTurbVaultRoute
  '/_authenticated/_navigable/games/donate-list': typeof AuthenticatedNavigableGamesDonateListLazyRoute
  '/_authenticated/_navigable/games/treasure': typeof AuthenticatedNavigableGamesTreasureLazyRoute
  '/_authenticated/_navigable/wallet/$address': typeof AuthenticatedNavigableWalletAddressLazyRoute
  '/_authenticated/_navigable/wallet/deposit': typeof AuthenticatedNavigableWalletDepositLazyRoute
  '/_authenticated/_navigable/wallet/withdraw': typeof AuthenticatedNavigableWalletWithdrawLazyRoute
  '/_authenticated/_navigable/wallet/withdraw-wallet': typeof AuthenticatedNavigableWalletWithdrawWalletLazyRoute
  '/_authenticated/_navigable/_index/': typeof AuthenticatedNavigableIndexIndexLazyRoute
  '/_authenticated/_navigable/profile/': typeof AuthenticatedNavigableProfileIndexLazyRoute
  '/_authenticated/_navigable/quest/': typeof AuthenticatedNavigableQuestIndexLazyRoute
  '/_authenticated/_navigable/ranking/': typeof AuthenticatedNavigableRankingIndexLazyRoute
  '/_authenticated/_navigable/wallet/': typeof AuthenticatedNavigableWalletIndexLazyRoute
  '/_authenticated/_navigable/_index/communities/$mint': typeof AuthenticatedNavigableIndexCommunitiesMintLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/authorize/phantom'
    | '/games/treasure/share'
    | '/games/turb-vault'
    | '/games/donate-list'
    | '/games/treasure'
    | '/wallet/$address'
    | '/wallet/deposit'
    | '/wallet/withdraw'
    | '/wallet/withdraw-wallet'
    | '/'
    | '/profile'
    | '/quest'
    | '/ranking'
    | '/wallet'
    | '/communities/$mint'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/authorize/phantom'
    | '/games/treasure/share'
    | '/games/turb-vault'
    | '/games/donate-list'
    | '/games/treasure'
    | '/wallet/$address'
    | '/wallet/deposit'
    | '/wallet/withdraw'
    | '/wallet/withdraw-wallet'
    | '/'
    | '/profile'
    | '/quest'
    | '/ranking'
    | '/wallet'
    | '/communities/$mint'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_authenticated/_navigable'
    | '/authorize/phantom'
    | '/_authenticated/_navigable/_index'
    | '/games/treasure/share'
    | '/_authenticated/_navigable/games/turb-vault'
    | '/_authenticated/_navigable/games/donate-list'
    | '/_authenticated/_navigable/games/treasure'
    | '/_authenticated/_navigable/wallet/$address'
    | '/_authenticated/_navigable/wallet/deposit'
    | '/_authenticated/_navigable/wallet/withdraw'
    | '/_authenticated/_navigable/wallet/withdraw-wallet'
    | '/_authenticated/_navigable/_index/'
    | '/_authenticated/_navigable/profile/'
    | '/_authenticated/_navigable/quest/'
    | '/_authenticated/_navigable/ranking/'
    | '/_authenticated/_navigable/wallet/'
    | '/_authenticated/_navigable/_index/communities/$mint'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  AuthorizePhantomLazyRoute: typeof AuthorizePhantomLazyRoute
  GamesTreasureShareLazyRoute: typeof GamesTreasureShareLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  AuthorizePhantomLazyRoute: AuthorizePhantomLazyRoute,
  GamesTreasureShareLazyRoute: GamesTreasureShareLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/authorize/phantom",
        "/games/treasure/share"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_navigable"
      ]
    },
    "/_authenticated/_navigable": {
      "filePath": "_authenticated/_navigable.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_navigable/_index",
        "/_authenticated/_navigable/games/turb-vault",
        "/_authenticated/_navigable/games/donate-list",
        "/_authenticated/_navigable/games/treasure",
        "/_authenticated/_navigable/wallet/$address",
        "/_authenticated/_navigable/wallet/deposit",
        "/_authenticated/_navigable/wallet/withdraw",
        "/_authenticated/_navigable/wallet/withdraw-wallet",
        "/_authenticated/_navigable/profile/",
        "/_authenticated/_navigable/quest/",
        "/_authenticated/_navigable/ranking/",
        "/_authenticated/_navigable/wallet/"
      ]
    },
    "/authorize/phantom": {
      "filePath": "authorize/phantom.lazy.tsx"
    },
    "/_authenticated/_navigable/_index": {
      "filePath": "_authenticated/_navigable/_index.tsx",
      "parent": "/_authenticated/_navigable",
      "children": [
        "/_authenticated/_navigable/_index/",
        "/_authenticated/_navigable/_index/communities/$mint"
      ]
    },
    "/games/treasure/share": {
      "filePath": "games/treasure/share.lazy.tsx"
    },
    "/_authenticated/_navigable/games/turb-vault": {
      "filePath": "_authenticated/_navigable/games/turb-vault.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/games/donate-list": {
      "filePath": "_authenticated/_navigable/games/donate-list.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/games/treasure": {
      "filePath": "_authenticated/_navigable/games/treasure.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/wallet/$address": {
      "filePath": "_authenticated/_navigable/wallet/$address.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/wallet/deposit": {
      "filePath": "_authenticated/_navigable/wallet/deposit.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/wallet/withdraw": {
      "filePath": "_authenticated/_navigable/wallet/withdraw.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/wallet/withdraw-wallet": {
      "filePath": "_authenticated/_navigable/wallet/withdraw-wallet.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/_index/": {
      "filePath": "_authenticated/_navigable/_index/index.lazy.tsx",
      "parent": "/_authenticated/_navigable/_index"
    },
    "/_authenticated/_navigable/profile/": {
      "filePath": "_authenticated/_navigable/profile/index.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/quest/": {
      "filePath": "_authenticated/_navigable/quest/index.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/ranking/": {
      "filePath": "_authenticated/_navigable/ranking/index.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/wallet/": {
      "filePath": "_authenticated/_navigable/wallet/index.lazy.tsx",
      "parent": "/_authenticated/_navigable"
    },
    "/_authenticated/_navigable/_index/communities/$mint": {
      "filePath": "_authenticated/_navigable/_index/communities/$mint.lazy.tsx",
      "parent": "/_authenticated/_navigable/_index"
    }
  }
}
ROUTE_MANIFEST_END */
